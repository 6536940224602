import React, {Fragment, lazy, Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';
import DashboardLayout from './layouts/DashoboardLayout';
import GuestGuard from "./components/GuestGuard";
import AuthGuard from "./components/AuthGuard";
import SlashScreen from "./components/LoadingScreen";
import ErrorBoundary from "./ErrorBoundary";

export const renderRoutes = (routes = []) => (
    <ErrorBoundary>
        <Suspense fallback={<SlashScreen/>}>
            <Switch>
                {routes.map((route, i) => {
                    const Guard = route.guard || Fragment;
                    const Layout = route.layout || Fragment;
                    const Component = route.component;

                    return (
                        <Route
                            key={i}
                            path={route.path}
                            exact={route.exact}
                            render={(props) => (
                                <Guard>
                                    <Layout>
                                        {route.routes ? (
                                            renderRoutes(route.routes)
                                        ) : (
                                            <Component {...props} />
                                        )}
                                    </Layout>
                                </Guard>
                            )}
                        />
                    );
                })}
            </Switch>
        </Suspense>
    </ErrorBoundary>
);

const routes = [
    {
        exact: true,
        guard: GuestGuard,
        path: '/login',
        component: lazy(() => import('./views/Login'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/rfd/products/:productId',
        component: lazy(() => import('./views/TataQl'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/rfd/products/payments/:Id',
        component: lazy(() => import('./views/TataQl/CheckOut'))
    },
    {
        path: '/',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                path: '/',
                component: lazy(() => import('./views/dashboard')),
            },
            {
                exact: true,
                path: '/dashboard',
                component: lazy(() => import('./views/dashboard')),
            },
            {
                exact: true,
                path: '/payments',
                component: lazy(() => import('./views/payments'))
            },
            {
                exact: true,
                path: '/assets',
                component: lazy(() => import('./views/myAssets'))
            },
            {
                exact: true,
                path: '/datumOrders',
                component: lazy(() => import('./views/myAssets/AllDatumOrders/OrdersForAllDatum'))
            },
            {
                exact: true,
                path: '/assetsLocation',
                component: lazy(() => import('./views/myAssets/MapViewForAsset'))
            },
            {
                exact: true,
                path: '/newassets',
                component: lazy(() => import('./views/myAssets/AssetDashbord'))
            },
            {
                exact: true,
                path: '/editAsset',
                component: lazy(() => import('./views/myAssets/EditAsset'))
            },
            {
                exact: true,
                path: '/myOrders/:tab',
                component: lazy(() => import('./views/myOrders'))
            },
            {
                exact: true,
                path: '/myOrders',
                component: lazy(() => import('./views/myOrders'))
            },
            {
                exact: true,
                path: '/orders',
                component: lazy(() => import('./views/OrdersPage'))
            },
            {
                exact: true,
                path: '/allDatumOrders/:tab',
                component: lazy(() => import('./views/myAssets/DatumFlow/DatumOrders'))
            },
            {
                exact: true,
                path: '/allDatumOrders',
                component: lazy(() => import('./views/myAssets/DatumFlow/DatumOrders'))
            },
            {
                exact: true,
                path: '/orders/:id',
                component: lazy(() => import('./views/OrdersPage'))
            },
            {
                exact: true,
                path: '/EditBankDetails',
                component: lazy(() => import('./views/Profile/Profile/EditBankDetail'))
            },
            {
                exact: true,
                path: '/order/detail/:id',
                component: lazy(() => import('./views/OrderDetail/Components/OrderDetail'))
            },
            {
                exact: true,
                path: '/NewOrders',
                component: lazy(() => import('./views/NewOrders'))
            },
            {
                exact: true,
                path: '/NewOrders/:id',
                component: lazy(() => import('./views/NewOrders'))
            },
            {
                exact: true,
                path: '/Profile',
                component: lazy(() => import('./views/Profile'))
            },
            {
                exact: true,
                path: '/onBoarding',
                component: lazy(() => import('./OnBoardingScreen/OnBoarding.js'))
            },

            {
                exact: true,
                path: '/Datums',
                component: lazy(() => import('./views/myAssets/DatumFlow/Datum.js'))
            },
            {
                exact: true,
                path: '/Datums/:tab',
                component: lazy(() => import('./views/myAssets/DatumFlow/Datum.js'))
            },
            {
                exact: true,
                path: '/DatumOrders',
                component: lazy(() => import('./views/myAssets/DatumFlow/Datum.js'))
            },
            //temprory
            {
                exact: true,
                path: '/newuser',
                component: lazy(() => import('./views/NegativeFlow/NegativeFlow/NewUserDashboard'))
            },
            {
                exact: true,
                path: '/dialog',
                component: lazy(() => import('./dialogs/DemoPage'))
            },
            {
                exact: true,
                path: '/despence',
                component: lazy(() => import('./views/myAssets/AssetPages'))
            },
            {
                exact: true,
                path: '/AssetData',
                component: lazy(() => import('./views/myAssets/NonDatumAsset'))
            },
            {
                exact: true,
                path: '/refill',
                component: lazy(() => import('./views/myAssets/Assetpage2'))
            },
            {
                exact: true,
                path: '/transactions',
                component: lazy(() => import('./views/myAssets/DatumReport/DatumRefill'))
            },
            {
                exact: true,
                path: '/newasset',
                component: lazy(() => import('./views/myAssets/AssetDashbord'))
            },
            {
                exact: true,
                path: '/refueler',
                component: lazy(() => import('./views/Refueler'))
            },
            {
                exact: true,
                path: '/OrderSummary',
                component: lazy(() => import('./views/OrderSummary'))
            },
            {
                exact: true,
                path: '/OrderSuccess',
                component: lazy(() => import('./views/OrderSuccess'))
            },
            {
                exact: true,
                path: '/PaymentFailed',
                component: lazy(() => import('./views/PaymentFailed'))
            },
            {
                exact: true,
                path: '/PaymentSuccess',
                component: lazy(() => import('./views/PaymentSuccess'))
            },
            {
                exact: true,
                path: '/QRCodeGen',
                component: lazy(() => import('./views/placeOrderFor4GiOT'))
            },
            {
                exact: true,
                path: '/Help',
                component: lazy(() => import('./views/Profile/Profile/Help'))
            },
            {
                exact: true,
                path: '/referrals',
                component: lazy(() => import('./views/Profile/Profile/Referral'))
            },
            {
                exact: true,
                path: '/addReferral',
                component: lazy(() => import('./views/Profile/Profile/AddReferral'))
            },
            {
                exact: true,
                path: '/AccountSetting',
                component: lazy(() => import('./views/Profile/Profile/AccountSetting'))
            },
            {
                exact: true,
                path: '/ChangePassword',
                component: lazy(() => import('./views/Profile/Profile/ChangePassword'))
            },
            {
                exact: true,
                path: '/ForgotPassword',
                component: lazy(() => import('./views/Profile/Profile/ForgotPassword'))
            },
            {
                exact: true,
                path: '/ForgotPassOTP',
                component: lazy(() => import('./views/Profile/Profile/ForgotPassOTP'))
            },
            {
                exact: true,
                path: '/Complaint',
                component: lazy(() => import('./views/Profile/Profile/RaiseComplaint'))
            },
            {
                exact: true,
                path: '/TicketDetails',
                component: lazy(() => import('./views/Profile/Profile/TicketDetails'))
            },
            {
                exact: true,
                path: '/CancelOrder/:id',
                component: lazy(() => import('./views/OrdersPage/Orders/CancelOrder'))
            },
            {
                exact: true,
                path: '/SellersTab',
                component: lazy(() => import('./views/payments/SellersTab/SellersMainTab'))
            },
            {
                exact: true,
                path: '/datumOrder',
                component: lazy(() => import('./views/myAssets/NonDatumAsset/DatumOrder'))
            },
            {
                exact: true,
                path: '/Datum/:tab',
                component: lazy(() => import('./views/myAssets/DatumFlow/Datum'))
            },
            {
                exact: true,
                path: '/Datum',
                component: lazy(() => import('./views/myAssets/DatumFlow/Datum'))
            },
            {
                exact: true,
                path: '/analytics',
                component: lazy(() => import('./views/myAssets/SubAssetAnalytics/index'))
            },
            {
                exact: true,
                path: '/codingPlayground',
                component: lazy(() => import('./views/codingPGround/Coding'))
            },
            {
                exact: true,
                path: '/eLock',
                component: lazy(() => import('./views/E-Lock/E-LockFlow/ELockApproval.js'))
            },
        ]
    },

]

export default routes